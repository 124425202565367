// Npm imports
import * as iziToast from 'izitoast';

// Local imports
import {ajax} from './ajax';
import {startFormLoading, endFormLoading} from './helpers';

// Constants
const noDataEl = " action attempted, but no data.el supplied";
const noElementFound = "action attempted, but no elements found for data.el=";

// Poll array - don't poll these if they're already being polled
let pollUrls = [];

/** DOM manipulation functions */
class Dom {
  /**
   * Add a toast message to the DOM
   * @param {Object} data - Data for the message
   * @param {string} data.message - Message to be shown
   * @param {string} [data.type=info] - Type of message (can be 'success', 'info' or 'error')
   */
  message(data) {
    if (!data.message || data.message === '') return;

    let opts = {
      message: data.message,
      theme: 'bulma',
      pauseOnHover: true,
      position: 'bottomLeft',
      progressBar: false
    };

    iziToast[data.type || 'info'].call(iziToast, opts);
  }

  /**
   * Add an inline error to a form
   * @param {Object} data - Data containing the form error
   * @param {string} data.message - Error message to be added
   * @param {string} data.el - Target element's 'name' attribute, used to find the element
   */
  formError(data) {
    let $el = $(`[name=${data.el}]`);
    
    if ($el.length === 0) {
      console.warn(`'formError' ${noElementFound}[name=${data.el}]`);
      
      App.trigger('message', [{
        type: 'error',
        message: data.message
      }]);
      
      return;
    }
    
    let nodeName = $el[0].nodeName.toLowerCase();
    let elType = $el[0].type.toLowerCase();
    let $target = $el;
    
    if (nodeName === 'select' || nodeName === 'file') {
      $target = $el.closest('.' + nodeName);
    }
    
    if (elType === 'checkbox' || elType === 'radio') {
      let $newTarget = $el.closest('.fancy-input-container');
      
      if ($newTarget.length === 0) {
        $target = $el.closest('.' + elType);
      } else {
        $target = $newTarget.nextAll('label');
      }
    }

    $target.after(`<p class="tag is-danger form-error">${data.message}</p>`);
    $target.addClass('is-danger');
  }

  /**
   * Replace an element with new data
   * @param {Object} data - Data containing the elements to update
   * @param {string} data.el - Target element   
   * @param {string} data.html - HTML to be added to the element
   */
  replace(data) {
    if (!data.el) return console.warn("'replace'" + noDataEl);
    
    let $el = $(data.el);

    if ($el.length === 0) return console.warn(`'replace' ${noElementFound}${data.el}`);

    $el.html(data.html);
    
    if (data.html.indexOf('autofocus') > 0) {
      setTimeout(function(){
        $el.find('[autofocus]').focus();
      }, 30);
    }
    
    App.components.reAdd(data.el);
  }

  /**
   * Append new HTML to an element
   * @param {Object} data - Data containing the target to update
   * @param {string} data.el - Target element   
   * @param {string} data.html - HTML to be appended to the element
   */
  append(data) {
    if (!data.el) return console.warn("'append'" + noDataEl);
    
    let $el = $(data.el);

    if ($el.length === 0) return console.warn(`'append' ${noElementFound}${data.el}`);

    $el.append(data.html);
    
    App.components.reAdd(data.el);
  }
  
  /**
   * Update a target with new attributes
   * @param {Object} data - Data containing the elements to update
   * @param {string} data.el - Target element
   * @param {Object} data.attr - Attribute object containing attribute(key)/value pairs
   */
  update(data) {
    if (!data.el) return console.warn("'update'" + noDataEl);
    
    let $el = $(data.el);

    if ($el.length === 0) return console.warn(`'update' ${noElementFound}${data.el}`);

    try {
      for (let key in data.attr) {
        const val = data.attr[key]
        $el.attr(key, val);
        
        if (key === 'value') $el.val(val);
      }
    } catch(e) {
      console.warn(e);
    }
  }

  /**
   * Show an element
   * @param {Object} data - Data containing the elements to show
   * @param {string} data.el - Target element
   */
  show(data) {
    if (!data.el) return console.warn("'show'" + noDataEl);
    
    let $el = $(data.el);

    if ($el.length === 0) return console.warn(`'show' ${noElementFound}${data.el}`);

    $el.show();
  }

  /**
   * Hide an element
   * @param {Object} data - Data containing the elements to hide
   * @param {string} data.el - Target element
   */
  hide(data) {
    if (!data.el) return console.warn("'hide'" + noDataEl);
    
    let $el = $(data.el);

    if ($el.length === 0) return console.warn(`'hide' ${noElementFound}${data.el}`)

    $el.hide();
  }

  /**
   * Remove an element
   * @param {Object} data - Data containing the elements to remove
   * @param {string} data.el - Target element
   */
  remove(data) {
    if (!data.el) return console.warn("'remove'" + noDataEl);
    
    let $el = $(data.el);

    if ($el.length === 0) return console.warn(`'remove' ${noElementFound}${data.el}`);

    $el.remove();
  }

  /**
   * Add a class to an element
   * @param {Object} data - Data containing the element to modify
   * @param {string} data.el - Target element
   * @param {string} data.class - Class name to add
   */
  addClass(data) {
    if (!data.el) return console.warn("'addClass'" + noDataEl);

    let $el = $(data.el);

    if ($el.length === 0) return console.warn(`'addClass' ${noElementFound}${data.el}`);

    // Add a slight delay so that removeClass will always be triggered first
    setTimeout(function(){
      $el.addClass(data.class);
    }, 0);
  }

  /**
   * Remove a class from an element
   * @param {Object} data - Data containing the element to modify
   * @param {string} data.el - Target element
   * @param {string} data.class - Class name to add
   */
  removeClass(data) {
    if (!data.el) return console.warn("'removeClass'" + noDataEl);

    let $el = $(data.el);

    if ($el.length === 0) return console.warn(`'removeClass' ${noElementFound}${data.el}`);

    $el.removeClass(data.class);
  }

  /**
   * Add a modal window
   * @param {Object} data - Data containing the html to add
   * @param {string} data.html - HTML to add to the modal window
   */
  modal(data) {
    const selector = '#page-modal';
    const $modal = $(selector);
    const $modalContent = $modal.find('.modal-content');
    const $html = $(data.html);
    const parentStyles = $html.data('parent-styles');

    if (parentStyles) {
      $modalContent.css(parentStyles);
    } else {
      $modalContent.removeAttr('style');
    }

    $modalContent.html($html);
    $modal.addClass('is-active');
    $modalContent.find('input').first().focus();
    
    App.components.reAdd(selector);
  }

  /**
   * Close modals
   */
  closeModals() {
    const $modal = $('#page-modal');
    const $modalContent = $modal.find('.modal-content');

    $modal.removeClass('is-active').one('transitionend', () => {
      $modalContent.removeAttr('style');
    });
  }
  
  /**
   * Add a date to an element. Uses formatting from https://date-fns.org/v2.1.0/docs/format
   * @param {Object} data - Data containing the element to format
   * @param {string} data.el - Target datetime element
   */
  addDate(data) {
    App.components.reAdd(data.el, 'dateTime');
  }
  
  /**
   * Poll a URL
   * @param {Object} data - Data containing URL to poll
   * @param {string} data.url - Url to poll
   * @param {string} data.wait - Time to wait between polls
   * @param {string} [data.limit] - Limit number of times to poll
   */
  poll(data) {
    if (pollUrls.includes(data.url)) return;

    pollUrls.push(data.url);

    const limit = data.limit || 1e50;
    const wait = data.wait || 1500;
    let limitCount = 0;
    let interval;
    
    console.info(`Polling ${data.url} in ${data.wait}ms`);
    
    interval = setInterval(function(){
      if (limitCount >= limit) {
        clearInterval(interval);
        return;
      }

      ajax.get(data.url);
      limitCount++;
    }, wait);
  }
  
  /**
   * Redirect browser to new url
   * @param {Object} data - Data containing URL to redirect to
   * @param {string} data.url - Url to redirect to
   */
  redirect(data) {
    console.info('Redirecting to ' + data.url);
    window.location = data.url;
  }
  
  /**
   * Add loading to a form, and disable elements
   */
  addLoadingToForm(data) {
    const $form = $(data.el);
    
    startFormLoading($form);
  }
  
  /**
  * Remove loading (and error messages) from a form, and enable elements
  */
  removeLoadingFromForm(data) {
    const $form = $(data.el);
    
    endFormLoading($form);
  }
}

export const dom = new Dom();
