// Event bindings
export const bindings = {
  document: documentError
}


export function documentError(e) {
  // Only deal with image errors. Can be expanded if required.
  if (e.target.nodeName !== 'IMG') return true;

  const $img = $(e.target);
  const $parent = $img.parent();
  const $media = $img.closest('.media-left');
  const fallback = $img.data('fallback');

  if (fallback) {
    $img.attr('src', fallback);
    return;
  }

  if ($media.length !== 0) {
    $media.hide();
  } else if ($parent.hasClass('image')) {
    $parent.hide();
  } else {
    $img.hide();
  }
}
