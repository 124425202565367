// Event bindings
export const bindings = {
  'label.fancy-input': selectFancyInput,
  '.fancy-input-simple' : selectSimpleFancyInput
}


export function selectFancyInput(e) {
  if (e.which !== 32) return true; // Spacebar only
  
  const $el = $(this);
  
  $el.prev('input').click();
  
  e.stopPropagation();
  e.preventDefault();
}

export function selectSimpleFancyInput(e) {
  if (e.which !== 32) return true; // Spacebar only

  const $el = $(this);
  const $row = $el.closest('tr');
  const $table = $el.closest('.dataTable');
  const isInDatatable = ($table.length !== 0);

  e.preventDefault();

  if (isInDatatable) {
    const row = $table.DataTable({ retrieve: true }).row($row[0]);

    if (row.node().classList.contains('is-selected')) {
      row.deselect();
      console.log('yes');
    } else {
      row.select();
      console.log('test');
    }
  } else {
    $el.toggleClass('is-selected');
  }
}
