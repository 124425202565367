// Import local
import * as helpers from '../helpers';


// Event bindings
export const bindings = {
  '.fancy-input-container input': fancyInput,
  '.toggle-pass': togglePassword,
  '[type=number]': changeNumberInput,
  '.ajax-change-submit': ajaxChangeSubmit,
  '[type=checkbox].show-on-select': showOnSelectCheckbox,
  '.file-input' : updateFilename
}


// ------ Change functions ------

export function fancyInput(e) {
  const $el = $(this);
  const $label = $el.next('label');
  const label = $el.prop('checked') ? 'Selected' : 'Deselected';
  
  $label.attr('aria-label', label);
}

export function togglePassword(e) {
  const target = $(this)[0].dataset.target.split(',');
  
  if (!target) throw 'data-target not supplied on change-password element; no actions taken';
  if (target[0].length === 0) throw 'data-target is a blank string; no actions taken';
  
  target.forEach(el => {
    const $target = $(el);

    let targetType = $target.attr('type') === 'password' ? 'text': 'password';

    $target.attr('type', targetType);
  });
}

export function changeNumberInput(e) {
  const val = e.target.value === '' ? null : parseFloat(e.target.value);
  const min = e.target.min === '' ? null : parseFloat(e.target.min);
  const max = e.target.max === '' ? null : parseFloat(e.target.max);
  const step = e.target.step === '' ? null : parseFloat(e.target.step);
  
  if (!val) return;
  
  if (max && val > max) {    
    return e.target.value = max;
  }
  
  if (min && val < min) {
    return e.target.value = min;
  }
  
  if (step && val%step !== 0) {
    e.target.value = val - val%step;
  }
}

export function ajaxChangeSubmit(e) {
  const $el = $(this);
  const $elForm = $('#' + $el.attr('form'));
  const $form = ($elForm.length > 0) ? $elForm : $el.closest('form');
  const form = $form[0];
  
  // Form 'submit' action was failing when trying to submit a form that was different to the target's form.
  // @TODO - investigate and attempt to use form.submit() instead of hidden button
  helpers.triggerFormSubmit(form);
}

// @TODO may need to replicate similar functionality added to 'show-on-select-radio' whereby required inputs
// have their 'required' attribute removed when being hidden by a container
export function showOnSelectCheckbox(e) {
  const target = $(this).data('target');
  
  if (!target) throw 'data-target must be supplied';
  
  const $target = $(target);
  
  if ($target.length === 0) throw 'data-target found on checkbox, but target wasnt found in the DOM';
  
  $target.toggleClass('is-hidden');
}

export function updateFilename(e) {
  const fileInput = $(this).prop("files");
  if (fileInput.length > 0) {
    const $label = $(this).closest('label').find('span.file-name');
    $label.html(fileInput[0].name);
  }
}
