// Local imports
import {ajax} from '../ajax';
import {dom} from '../dom';


// Event bindings
export const bindings = {
  '.dropdown:not(.is-hoverable) .dropdown-trigger': dropdownExpand,
  '.navbar-link': navbarDropdown,
  '.navbar-burger': navbarExpand,
  '.modal-close, .modal-background, .modal-cancel': closeModal,
  '.modal-trigger': openModal,
  '.ajax-link': followLink,
  '.toggle-password': togglePassword
}


// ------ Click functions ------

export function dropdownExpand(e) {
  e.preventDefault();
  
  const $el = $(this);
  const $target = $el.closest('.dropdown');
  const $tabs = $el.closest('.tabs');
  const isInTabs = $tabs.length > 0;
  
  const addDocumentClick = function(){
    $(document).on('click.dropdownClick', e2 => {
      e2.stopPropagation();

      let clickedDropdown = $(e2.target).closest('.dropdown-menu').length !== 0;

      if (clickedDropdown) return;
      if (e2.target.className === 'temp-submit') return;
      
      $target.removeClass('is-active');
      
      if (isInTabs) {
        const $thisTabs = $(e2.target).closest('.tabs');
        
        // Only remove tab overflow visibility (with 'has-active-dropdown') on tabs if clicking a different set of tabs
        if (!$tabs.is($thisTabs)) {
          $tabs.removeClass('has-active-dropdown');
        }
      }
      
      $(document).off('click.dropdownClick');
    });
  }
  
  if (!$target.hasClass('is-active')) {
    setTimeout(addDocumentClick, 30);
  }
  
  if (isInTabs) $tabs.addClass('has-active-dropdown');
  $target.toggleClass('is-active');
}

export function navbarDropdown(e) {
  e.preventDefault();

  const $el = $(this);
  const $target = $el.parent('.navbar-item');

  const addDocumentClick = function(){
    $(document).on('click.navbarDropdownClick', e2 => {
      e2.stopPropagation();

      let clickedDropdown = $(e2.target).closest('.navbar-dropdown').length !== 0;

      if (clickedDropdown) return;

      $target.removeClass('is-active');
      $(document).off('click.navbarDropdownClick');
    });
  }

  if (!$target.hasClass('is-active')) {
    setTimeout(addDocumentClick, 30);
  }

  $target.toggleClass('is-active');

  const $input = $target.find('.input');

  if ($input.length !==0) $input.focus();
}

export function navbarExpand(e) {
  const $el = $(this);
  const $target = $($el.data('target'));

  $el.toggleClass('is-active');
  $target.toggleClass('is-active');
}

export function closeModal(e) {
  e.stopPropagation();
  e.preventDefault();

  dom.closeModals();
}

export function openModal(e) {
  e.preventDefault();

  const $el = $(this);
  const src = $el.data('src');
  
  // data-src can be used for both URL fetching and DOM target fetching 
  if (!src) throw 'data-src wasn\'t found on modal trigger - doing nothing';
  
  // data-src is a URL
  if (src.indexOf('http') === 0) {
    let xhr = ajax.get(src);
    
    xhr.then(function(res) {
      // Events were supplied - assume an 'update' response has been sent to update the modal
      if (res.data.events) return;
      
      // No events supplied - it is probably a HTML response.
      if (res.data && res.status >= 200 && res.status < 300) {
        dom.modal({
          html: res.data
        });
      }
    })
  } else {
    // data-src is a DOM target
    const $src = $(src);
    
    if ($src.length === 0) throw 'data-src target element wasn\'t found';
    
    dom.modal({
      html: $src.html()
    });
  }
}

export function followLink(e) {
  e.preventDefault();
  
  const $el = $(this);
  const req = ajax.get($el.attr('href'));

  if (!$el.hasClass('button')) return;

  $el.addClass('is-loading');

  req.finally(() => {
    $el.removeClass('is-loading');
  })
}

export function togglePassword(e){
  e.preventDefault();

  const $el = $(this);
  $el.toggleClass("fa-eye fa-eye-slash");
  var input = $($el.attr("toggle"));
  if (input.attr("type") == "password") {
    input.attr("type", "text");
  } else {
    input.attr("type", "password");
  }
}
