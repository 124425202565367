import {ajax} from '../ajax';
import * as helpers from '../helpers';


// Event bindings
export const bindings = {
  'form:not(".no-ajax")': form
}


// ------ Submit functions ------

export function form(e) {
  e.preventDefault();
  
  const $form = $(e.target);
  const $disabled = $form.find('fieldset:disabled input:checked');

  const GoogleRecaptcha = new Promise((resolve, reject) => {
    // handle Google ReCaptcha
    if ($form.hasClass('is-recaptcha')){
      if (typeof recaptcha_public_key === 'undefined'){
        reject('GoogleRecaptcha Failure!')
      }else{
        grecaptcha.execute(recaptcha_public_key, {action: 'homepage'}).then(function(token) {
          // add token value to form
          $form.find('#g-recaptcha-response').val(token);
          resolve('GoogleRecaptcha Success!');
        });
      }
    } else {
      resolve('GoogleRecaptcha not needed');
    }
  });

  GoogleRecaptcha.then((value) => {
    //console.log(value);
    let data = $form.serializeArray();
    $disabled.each((i,el) => {
      data.push({ name: el.name, value: el.value });
    });

    // Encode value for axios to de-encode
    let dataString = data.map(el => el.name + '=' + encodeURIComponent(el.value)).join('&');
    
    if ($form.hasClass('is-loading')) return;
    
    helpers.startFormLoading($form);
    
    const action = document.activeElement.getAttribute('formaction') || e.target.action;

    let req = ajax.post(action, dataString);

    // Need to only end form loading if not redirecting, so can't use 'finally'
    req.then(res => {
      if (!res.data || !res.data.events || !res.data.events.find(obj => obj.hasOwnProperty('redirect'))) {
        helpers.endFormLoading($form);
      }
    }).catch(() => {
      helpers.endFormLoading($form);
    });

    let dangerEls = [
      '.input.is-danger',
      '.select.is-danger',
      '.textarea.is-danger',
      '.checkbox.is-danger',
      '.radio.is-danger',
      '.file.is-danger'
    ];

    $form.find(dangerEls.join(',')).removeClass('is-danger');
    $form.find('.help.is-danger').remove();
  });
}
