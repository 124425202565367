'use strict';

// Npm imports
// Insert your npm imports here

// Local imports
import {events} from './main/events';
import {form} from './main/events/submit';
import {ajax} from './main/ajax';

// Bind events
events.bind();

window.App = {
  get: ajax.get,
  post: ajax.post,
  submitForm: (e) => { form(e) },
  trigger: (...args) => {
    events.trigger.call(events, ...args);
  },
  addLoadingToForm: (form) => {
    events.trigger('addLoadingToForm', [{ el: form }]);
  },
  removeLoadingFromForm: (form) => {
    events.trigger('removeLoadingFromForm', [{ el: form }]);
  }
};
