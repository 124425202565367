// Node module imports
import axios from 'axios';

// Local imports
import {events} from './events';


function success(res) {
  if (!res.data || !res.data.events) {
    return console.warn('"events" not supplied on response. Was this definitely an ajax response?');
  }
  
  res.data.events.forEach(event => {
    events.trigger(event.type, event.data);
  });
}

function error(error) {
  if (error.response && error.response.data) {
    if (!error.response.data.events) {
      if (typeof error.response.data === 'string') console.warn(error.response.data);
      if (error.response.data.message) {
        events.trigger('message', [{
          type: 'error',
          message: error.response.statusText + ': ' + error.response.data.message
        }])
      }
    } else {
      error.response.data.events.forEach(event => {
        events.trigger(event.type, event.data);
      });
    }
  } else {
    const message = error.message || error;
    
    console.log(message);

    events.trigger('message', [{
      type: 'error',
      message
    }]);
  }
}

class Ajax {
  get(url) {
    return new Promise((res, rej) => {
      const req = axios({
        url,
        method: 'get',
        headers: {'X-Requested-With': 'XMLHttpRequest'}
      });
      
      req.then(function(...args) {
        success(...args);
        res(...args);
      });
      
      req.catch(function(...args) {
        error(...args);
        rej(...args);
      });
    });
  }

  post(url, opts) {
    return new Promise((res, rej) => {      
      const req = axios({
        url,
        method: 'post',
        data: opts,
        headers: {'X-Requested-With': 'XMLHttpRequest'}
      });

      req.then(function(...args) {
        success(...args);
        res(...args);
      });
      
      req.catch(function(...args) {
        error(...args);
        rej(...args);
      });
    });
  }
}

export const ajax = new Ajax();
