// Npm imports


// App imports
import {dom} from './dom';
import * as click from './events/click';
import * as submit from './events/submit';
import * as change from './events/change';
import * as input from './events/input';
import * as focus from './events/focus';
import * as keydown from './events/keydown';
import * as error from './events/error';


// Hook bindings
const bindings = {
  click: click.bindings,
  submit: submit.bindings,
  change: change.bindings,
  input: input.bindings,
  focusin: focus.bindings,
  keydown: keydown.bindings,
  error: error.bindings
}


function eventHandler(triggeredEvent){
  let eventSpecificBindings = bindings[triggeredEvent.type];

  for (let element in eventSpecificBindings) {
    let $target = $(triggeredEvent.target);
    
    let targetElement = element;
    if (targetElement === 'document') targetElement = document;
    if (targetElement === 'body') targetElement = document.body;
    
    let $anyTarget = $target.closest(targetElement);
    
    if ($anyTarget.length !== 0) {
      eventSpecificBindings[element].call($anyTarget[0], triggeredEvent);
    }
  }
}

class Events {
  bind() {    
    for (let event in bindings) {
      const capture = (event === 'error');
      
      document.addEventListener(event, eventHandler, capture);
    }
  }

  trigger(event, data) {
    if (!event) return console.info('No event name supplied in ajax response');
    
    if (!data || data.length === 0) {
      dom[event].call(this);
    } else {
      data.forEach(task => {
        dom[event].call(this, task);
      });
    }
  }
}

export const events = new Events();
