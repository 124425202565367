let debounceTimeout;

export function debounce(fn, time=250) {
  return function() {
    const functionCall = () => fn.apply(this, arguments);

    clearTimeout(debounceTimeout);
    
    debounceTimeout = setTimeout(functionCall, time);
  }
}

export function startFormLoading($form) {
  const $submitButtons = $form.find('.button[type=submit]');
  const $inputs = $form.find('input:not([disabled]),textarea:not([disabled]),select:not([disabled])');

  $form.addClass('is-loading');
  $submitButtons.addClass('is-loading');
  $inputs.attr('disabled', 'disabled').addClass('was-not-disabled');
  const $errors = $form.find('.form-error');
  
  $errors.prev('.is-danger').removeClass('is-danger');
  $errors.remove();
  
  // temporary fix for alert-table-errors - should be using 'formError' response instead of 'update'
  $('.alert-table-error').empty();
}

export function endFormLoading($form) {
  const $submitButtons = $form.find('.button[type=submit]');
  const $inputs = $form.find('input.was-not-disabled,textarea.was-not-disabled,select.was-not-disabled');
  
  $form.removeClass('is-loading');
  $submitButtons.removeClass('is-loading');
  $inputs.removeAttr('disabled').removeClass('was-not-disabled');

  // focus on first form element that has an error
  $form.find('.input.is-danger,.textarea.is-danger').first().focus();
}

export function triggerFormSubmit(form) {
  let button = form.ownerDocument.createElement('input');
  button.style.display = 'none';
  button.className = 'temp-submit';
  button.type = 'submit';
  
  form.appendChild(button).click();
  form.removeChild(button);
}
