// Event bindings
export const bindings = {
  '.input.datatable-search': datatableSearch
}


// ------ Input functions ------

export function datatableSearch(e) {
  const target = $(this).data('target');
  const $tables = $(target);
  
  if ($tables.length === 0) throw 'data-target wasn\'t supplied or target tables couldn\'t be found';
  
  $tables.DataTable({ retrieve: true }).search(e.target.value).draw();
}
